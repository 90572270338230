import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import './Contactpage.css'

const ContactPage = () => {
  return (
    <Container fluid className="my-5" >
       <h2 className='h1-responsive font-weight-bold text-center' data-aos='fade-down'>Contact us</h2>
       <h4 className='h1-responsive font-weight-bold text-center' data-aos='fade-down'>
          Have questions or need assistance? We're here to help!
         </h4>
         <p className='text-center w-responsive mx-5 pb-5' data-aos='fade-down'>
           Feel free to reach out for more information, assistance
           with custom orders, or any inquiries you may have. Our
           dedicated customer service team is ready to provide prompt
           and helpful support.
         </p>
      <Row>
        <Col lg={5} className="lg-0 mb-4 form" >
          <Card >
            <Card.Body>
              <div className="form-header blue accent-1">
                <h3 className="mt-2">
                  <FaEnvelope /> Write to us:
                </h3>
              </div>
              <p className="dark-grey-text">
                We'll write rarely, but only the best content.
              </p>
              <Form>
                <Form.Group controlId="form-name">
                  <Form.Label>Your name</Form.Label>
                  <Form.Control type="text" placeholder="Your name" />
                </Form.Group>
                <Form.Group controlId="form-email">
                  <Form.Label>Your email</Form.Label>
                  <Form.Control type="text" placeholder="Your email" />
                </Form.Group>
                <Form.Group controlId="form-subject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" placeholder="Subject" />
                </Form.Group>
                <Form.Group controlId="form-text">
                  <Form.Label>Icon Prefix</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Your message" />
                </Form.Group>
                <div className="text-center">
                  <Button variant="light-blue" className="btn-primary" style={{backgroundColor: 'blue', marginTop: '15px'}} type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={7} className="map">
          <div
            id="map-container"
            className="rounded z-depth-1-half map-container"
            style={{ height: "400px" }}
          >
            <iframe
              title="This is a unique title"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d76765.98321148289!2d-73.96694563267306!3d40.751663750099084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2spl!4v1525939514494"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
          <br />
          <Row className="text-center">
            <Col md={4}>
              <Button variant="blue" className="accent-1" disabled>
                <FaMapMarkerAlt style={{ color: "blue", height: "30px" }} />
              </Button>
              <p>New York, 94126</p>
              <p className="mb-md-0">United States</p>
            </Col>
            <Col md={4}>
              <Button variant="blue" className="accent-1" disabled>
                <FaPhone style={{ color: "blue", height: "30px" }} />
              </Button>
              <p>+ 01 234 567 89</p>
              <p className="mb-md-0">Mon - Fri, 8:00-22:00</p>
            </Col>
            <Col md={4}>
              <Button variant="blue" className="accent-1" disabled>
                <FaEnvelope style={{ color: "blue", height: "30px" }} />
              </Button>
              <p>info@gmail.com</p>
              <p className="mb-md-0">sale@gmail.com</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;


// import React from 'react';
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
// // import './ContactPage.css'; 

// const ContactPage = () => {
//   return (
//     <Container fluid className="contact-container" >
//       <section className='my-1' data-aos='fade-up' style={{ color: "black" }}>
//         <h2 className='h1-responsive font-weight-bold text-center' data-aos='fade-down'>Contact us</h2>
//         <h4 className='h1-responsive font-weight-bold text-center' data-aos='fade-down'>
//           Have questions or need assistance? We're here to help!
//         </h4>
//         <p className='text-center w-responsive mx-5 pb-5' data-aos='fade-down'>
//           Feel free to reach out for more information, assistance
//           with custom orders, or any  inquiries you may have. Our
//           dedicated customer service team is ready to provide prompt
//           and helpful support.
//         </p>

//         <Row>
//           <Col lg={5} className='lg-0 mb-4' data-aos='fade-right'>
//             <Card>
//               <Card.Body>
//                 <div className='form-header blue accent-1'>
//                   <h3 className='mt-2'>
//                     <FaEnvelope /> Write to us:
//                   </h3>
//                 </div>
//                 <p className='dark-grey-text'>
//                   We'll write rarely, but only the best content.
//                 </p>
//                 <Form>
//                   <Form.Group controlId='form-name'>
//                     <Form.Label>Your name</Form.Label>
//                     <Form.Control type='text' placeholder='Your name' style={{ backgroundColor: "#f7f7f7" }} />
//                   </Form.Group>
//                   <Form.Group controlId='form-email'>
//                     <Form.Label>Your email</Form.Label>
//                     <Form.Control type='text' placeholder='Your email' style={{ backgroundColor: "#f7f7f7" }} />
//                   </Form.Group>
//                   <Form.Group controlId='form-subject'>
//                     <Form.Label>Subject</Form.Label>
//                     <Form.Control type='text' placeholder='Subject' style={{ backgroundColor: "#f7f7f7" }} />
//                   </Form.Group>
//                   <Form.Group controlId='form-text'>
//                     <Form.Label>Icon Prefix</Form.Label>
//                     <Form.Control as='textarea' rows={3} placeholder='Your message' style={{ backgroundColor: "#f7f7f7" }} />
//                   </Form.Group>
//                   <div className='text-center'>
//                     <Button variant='light-blue' className='btn-primary' type='submit' style={{ color: "white", marginTop: "10px" }}>
//                       Submit
//                     </Button>
//                   </div>
//                 </Form>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col lg={7} data-aos='fade-left'>
//             <div
//               id='map-container'
//               className='rounded z-depth-1-half map-container'
//               style={{ height: '400px' }}
//             >
//               <iframe
//                 title='This is a unique title'
//                 src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d76765.98321148289!2d-73.96694563267306!3d40.751663750099084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2spl!4v1525939514494'
//                 width='100%'
//                 height='100%'
//                 frameBorder='0'
//                 style={{ border: 0 }}
//                 allowFullScreen
//                 data-aos='fade-left'
//               />
//             </div>
//             <br />
//             <Row className='text-center'>
//               <Col md={4}>
//                 <Button variant='blue' className='accent-1' disabled style={{ border: "none", backgroundColor: "#f7f7f7" }}>
//                   <FaMapMarkerAlt style={{ color: "blue", height: "30px" }} />
//                 </Button>
//                 <p>New York, 94126</p>
//                 <p className='mb-md-0'>United States</p>
//               </Col>
//               <Col md={4}>
//                 <Button variant='blue' className='accent-1' disabled style={{ border: "none", backgroundColor: "#f7f7f7" }}>
//                   <FaPhone style={{ color: "blue", height: "30px" }} />
//                 </Button>
//                 <p>+ 01 234 567 89</p>
//                 <p className='mb-md-0'>Mon - Fri, 8:00-22:00</p>
//               </Col>
//               <Col md={4}>
//                 <Button variant='blue' className='accent-1' disabled style={{ border: "none", backgroundColor: "#f7f7f7" }}>
//                   <FaEnvelope style={{ color: "blue", height: "30px" }} />
//                 </Button>
//                 <p>info@gmail.com</p>
//                 <p className='mb-md-0'>sale@gmail.com</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </section>
//     </Container>
//   );
// };

// export default ContactPage;
