import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaBullhorn, FaCogs, FaTachometerAlt } from "react-icons/fa";

const AboutComponent = () => {
  return (
    <Container className="my-2" >
      <h4 className="h1-responsive font-weight-bold text-center my-3">
        Why is it so great?
      </h4>
      <p className="lead grey-text w-responsive text-center mx-auto mb-3">
      At Ubrikk, we take pride in delivering products that stand out in both quality and quantity. Our commitment to excellence is at the core of everything we do, ensuring that every customer receives a remarkable experience with our offerings.
      </p>

      <Row>
        <AboutFeature
          icon={<FaBullhorn size="2x" className="blue-text" />}
          title="Marketing"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
          color="primary"
        />
        <AboutFeature
          icon={<FaCogs size="2x" className="pink-text" />}
          title="Customization"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
          color="pink"
        />
        <AboutFeature
          icon={<FaTachometerAlt size="2x" className="purple-text" />}
          title="Support"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
          color="purple"
        />
      </Row>
    </Container>
  );
};

const AboutFeature = ({ icon, title, description, color }) => {
  return (
    <Col md={4} className="md-0 mb-5">
      <Row>
        <Col lg={2} md={3} xs={2}>
          {icon}
        </Col>
        <Col lg={10} md={9} xs={10}>
          <h4 className="font-weight-bold">{title}</h4>
          <p className="grey-text">{description}</p>
          
        </Col>
      </Row>
    </Col>
  );
};

export default AboutComponent;
