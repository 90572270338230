import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import servicebg1 from '../../assets1/servicebg1.png'
import bgimage from '../../assets/img/bg.jpg';
import {AiOutlineArrowLeft} from 'react-icons/ai'
import './servicepage.css'

const ServicePage = () => {
  return (
   <div style={{ backgroundImage: `url(${bgimage})`,
   backgroundSize: 'cover',
   backgroundRepeat: 'no-repeat',
   width: '100%',
   height: 'auto',
   minHeight: '900px',
   marginTop: '-47px',
   // marginRight: '-10%'
   }}>
       

    <section className="my-5" services >
    <div className='profile-row1' style={{ fontSize: '1.4em', paddingLeft: '5%', paddingTop: '10px', fontWeight: 'bolder', color: '#3A3A44' }}>
  <AiOutlineArrowLeft /> 
  <Link to='/' style={{ color: 'black', textDecoration: 'none' }}>
    &nbsp; <span>Back</span>
  </Link>
</div>

      <h2 className="h1-responsive font-weight-bold text-center my-2" style={{color: 'blue', 
    textDecoration: 'none',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 800,
   }} data-aos='fade-down'>
      Our Services
      </h2>
      <p className="lead grey-text w-responsive text-center mx-auto mb-5" style={{ paddingLeft: '10%', paddingRight: '10%',  color: 'grey',
    textDecoration: 'none',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 700,
    textAlign: 'center', }} data-aos='fade-down' >
  At Ubrikk, we offer a range of comprehensive services to meet the diverse needs of our clients. Our commitment to excellence and customer satisfaction drives us to provide top-notch solutions tailored to your requirements.
</p>


      <Row>
        <Col lg={5} className="text-center text-lg-left" data-aos='fade-left'>
          <Image
            fluid
            src={servicebg1}
            alt=""
            style={{width: '90%', height: '90%'}}
          />
        </Col>
        <Col lg={7} data-aos='fade-right'>
          <Row className="mb-3">
            <Col xs={1}>
              <i className="fas fa-share-alt fa-lg indigo-text"></i>
            </Col>
            <Col xl={10} md={11} xs={10}>
              <h5 className="font-weight-bold mb-3" >Planning</h5>
              <p className="grey-text">
              Our team of experienced professionals excels in strategic planning, ensuring that every aspect of your project, including online delivery logistics, is meticulously organized and executed. From conceptualization to implementation, we focus on delivering efficient and effective planning services to meet your business objectives.
        </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={1}>
              <i className="fas fa-share-alt fa-lg indigo-text"></i>
            </Col>
            <Col xl={10} md={11} xs={10}>
              <h5 className="font-weight-bold mb-3">Custom Products</h5>
              <p className="grey-text">
              We understand that every client has unique needs. Ubrikk offers customizable product solutions tailored to your specifications. Our team collaborates with you to design, develop, and deliver products that align with your specific requirements. Expect nothing less than excellence in custom product development from Ubrikk.
      
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={1}>
              <i className="fas fa-share-alt fa-lg indigo-text"></i>
            </Col>
            <Col xl={10} md={11} xs={10}>
              <h5 className="font-weight-bold mb-3">Online Delivery</h5>
              <p className="grey-text">
              Ubrikk specializes in providing reliable and efficient online delivery services for your products. Whether you're expanding your reach nationally or globally, our team navigates the complexities of e-commerce logistics, ensuring timely and secure deliveries. Count on us for seamless online exports and imports, connecting your products with customers worldwide.
     
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={1}>
              <i className="fas fa-share-alt fa-lg indigo-text"></i>
            </Col>
            <Col xl={10} md={11} xs={10}>
              <h5 className="font-weight-bold mb-3">Customer Support</h5>
              <p className="grey-text">
              At Ubrikk, customer satisfaction is our priority. Our dedicated customer support team is available to assist you at every stage of the online delivery process, addressing inquiries, providing order tracking information, and ensuring a seamless experience. We believe in building lasting relationships with our clients through exceptional customer support.
         </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
    </div>
  );
};

export default ServicePage;
