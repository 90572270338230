import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import {  Dropdown } from 'react-bootstrap';
import { Trash2 } from 'react-bootstrap-icons';
import { GoArrowRight } from "react-icons/go";
import Navbar from './Navbar';

import Sidenav from './Sidenav';

const Headers = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [scrollingUp, setScrollingUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollingUp(scrollTop < (window.lastScrollTop || 0));
      window.lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: 'transparent',
    color: 'black',
  };

  return (
    <>
      {/* Show Sidenav only on mobile devices */}
      <nav
        style={navbarStyle}
        variant="dark"
        expand="md"
        fixed="top"
        className="navbar navbar-expand-lg d-flex justify-content-between align-items-center"
      >
         {isMobile && <Navbar/>}
         <Link
  to="/"
  style={{
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 800,
    textAlign: 'center', // Center-align by default
  }}
>
  <h3
    style={{
      padding: '5px',
      color: 'black',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 500, fontSize: '23px', border: 'none',
      '@media (max-width: 1000px)': {
        textAlign: 'center', // Center-align when screen width is at most 1000px
      },
    }}
  >
    Ubrikk
  </h3>
</Link>

{isMobile &&
<>
<Dropdown className='dropdown'>
      <Dropdown.Toggle variant="secondary" style={{backgroundColor: 'transparent', color: 'black',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 500, fontSize: '23px', border: 'none' }}><span style={{
      fontWeight: 500,
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      color: 'black',
      backgroundColor: 'transparent',
    }}>
      <GoArrowRight />
    </span>{']'}</Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Header>Application</Dropdown.Header> */}
        <Dropdown.Item>
        <Link to="/signin" style={{ textDecoration: 'none', color: 'black' }}>
                
                  Login
                
              </Link>
              
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
        <Link to="/signup" style={{ textDecoration: 'none', color: 'black' }}>
                
                  Signup
                
              </Link>
        </Dropdown.Item>
      
{/* 
        <Dropdown.Divider />

       
        <Dropdown.Item className="text-danger">
          <Trash2 size={14} /> Delete my account
        </Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
    </>
}

        <div className="collapse navbar-collapse" id="navbarTogglerDemo03" style={{ marginLeft: '30%' }}>
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0" style={{ color: 'black', fontWeight: 'bold' }}>
          
          <li className="nav-item">
            <Link className="nav-link" to="/aboutus" style={{ color: 'black',  fontWeight: 'bold' }}>
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/productpage" style={{ color: 'black',  fontWeight: 'bold' }}>
              Products
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/services" style={{ color: 'black',  fontWeight: 'bold' }}>
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/vendorlist" style={{ color: 'black',  fontWeight: 'bold' }}>
              Vendors
            </Link>
          </li>
          </ul>

          {!isMobile && (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '40px' }}>
              <Link to="/signin" style={{ textDecoration: 'none', marginRight: '10px' }}>
                <button className="btn btn-outline-light" style={{ fontSize: '16px', backgroundColor: 'blue' }}>
                  Login
                </button>
              </Link>
              <Link to="/signup" style={{ textDecoration: 'none' }}>
                <button className="btn btn-outline-light" style={{ fontSize: '16px', backgroundColor: 'blue' }}>
                  Signup
                </button>
              </Link>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Headers;
