import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { IoIosArrowBack } from 'react-icons/io';
import bgimage from '../../assets/img/bg.jpg'
import './ProductPage.css'; // Import the CSS file
import RecommendedProducts from "./RecommendedProducts";
import Profileheader from "Pages/Profile/ProfileHeader/Profileheader";

const ProductPage = () => {
  return (
    <div
    style={{
      backgroundImage: `url(${bgimage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: 'auto',
      minHeight: '900px',
    }}
    >
      {/* <div>
      <Profileheader/>
      </div> */}
      <span className='profile-row1' style={{display: "flex", flexDirection: "row"}}><AiOutlineArrowLeft style={{color: 'black', marginTop: "5px"}} /> <Link to='/' style={{color: 'black', textDecoration: 'none'}}>&nbsp; <span>Back</span></Link></span>
    <Container>
      <section className="text-center my-3">
        <h2 className="h1-responsive font-weight-bold text-center my-3">
          <a href="/">
            {/* <IoIosArrowBack style={{ display: "flex", justifyContent: "left", textDecoration: "none" }} /> */}
          </a> Our Products
        </h2>
        <p className="grey-text text-center w-responsive mx-auto mb-5">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
          error amet numquam iure voluptate esse quasi, veritatis
          totam voluptas nostrum quisquam eum porro a pariatur veniam.
        </p>
        <Row>
          <Col lg="3" md="6" className="mb-lg-0 mb-4">
            <Card className="z-depth-1-half zoom-card">
              <div className="view zoom">
                <Card.Img style={{height: "80%", objectFit: "fill"}}
                  src="https://images.pexels.com/photos/10076380/pexels-photo-10076380.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  fluid
                  alt=""
                />
                <div className="stripe dark">
                  <a href="#!">
                    <p>
                      School Bags <i className="fas fa-angle-right"></i>
                    </p>
                  </a>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg="3" md="6" className="mb-lg-0 mb-4">
            <Card className="z-depth-1-half zoom-card">
              <div className="view zoom">
                <Card.Img
                  src="https://images.pexels.com/photos/5229536/pexels-photo-5229536.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  fluid
                  alt=""
                />
                <div className="stripe dark">
                  <a href="#!">
                    <p>
                      Cars <i className="fas fa-angle-right"></i>
                    </p>
                  </a>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg="3" md="6" className="mb-lg-0 mb-4">
            <Card className="z-depth-1-half zoom-card">
              <div className="view zoom">
                <Card.Img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/3/LD/HN/JS/8329209/4house-500x500.jpg"
                  fluid
                  alt=""
                />
                <div className="stripe dark">
                  <a href="#!">
                    <p>
                      Nameplates <i className="fas fa-angle-right"></i>
                    </p>
                  </a>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg="3" md="6" className="mb-lg-0 mb-4">
            <Card className="z-depth-1-half zoom-card">
              <div className="view zoom">
                <Card.Img
                  src="https://images.pexels.com/photos/12035709/pexels-photo-12035709.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  fluid
                  alt=""
                />
                <div className="stripe dark">
                  <a href="#!">
                    <p>
                      Labels <i className="fas fa-angle-right"></i>
                    </p>
                  </a>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </section>
      <RecommendedProducts/>
    </Container>
    </div>
  );
};

export default ProductPage;
