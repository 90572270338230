import React from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, LogoutUser } from 'redux/features/auth';

function Navbar() {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [isTablet, setIstablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const logout = () => dispatch(LogoutUser());

  const toggleHamburger = () => {
    setIsMobile(!isMobile);
    setIstablet(false); // Close the tablet menu when opening the hamburger menu
  };

  const toggleTablet = () => {
    setIstablet(!isTablet);
    setIsMobile(false); // Close the hamburger menu when opening the tablet menu
  };

  return (
    <div className='navbar'>
      <div className='mobile-menu' onClick={toggleHamburger}>
        {!isMobile ? <GiHamburgerMenu /> : <AiOutlineClose />}
      </div>
      <div className='logo'>
        <h4>Ubrikk</h4>
      </div>
      <div className='logo2'>
        <h4 className='h1-responsive text-center'>Ubrikk</h4>
      </div>
      <ul className={isMobile ? 'nav-list-mobile' : 'nav-list'}>
        <Link to='/aboutus' className='link-tab' onClick={() => setIsMobile(false)}>
          <li>About Us</li>
        </Link>
        <Link to='/productpage' className='link-tab' onClick={() => setIsMobile(false)}>
          <li>Products</li>
        </Link>
        <Link to='/servicepage' className='link-tab' onClick={() => setIsMobile(false)}>
          <li>Service</li>
        </Link>
        <Link to='/vendorlist' className='link-tab' onClick={() => setIsMobile(false)}>
          <li>Vendors</li>
        </Link>
      </ul>
      <div>
        {user ? (
          <ul className={isTablet ? 'nav-links-tablet' : 'nav-links'}>
            <li className='login'>{user.profile.full_name}</li>
            <li onClick={logout} className='signup'>
              Logout
            </li>
          </ul>
        ) : (
          <ul className={isTablet ? 'nav-links-tablet' : 'nav-links'}>
            <Link to='/signin' className='signup' onClick={toggleTablet}>
              <li>Login</li>
            </Link>
            <Link to='/signup' className='signup' onClick={toggleTablet}>
              <li>Signup</li>
            </Link>
          </ul>
        )}
      </div>
      <div className='tablet-menu' onClick={toggleTablet}>
        {!isTablet ? <FiLogIn /> : <AiOutlineClose />}
      </div>
    </div>
  );
}

export default Navbar;
