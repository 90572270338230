import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SlMenu } from 'react-icons/sl';
import './Sidenav.css'

function MyModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button style={{backgroundColor: 'transparent', border: 'none', fontWeight: '800'}} onClick={handleShow}>
        <SlMenu style={{backgroundColor: 'transperant', color: 'black', fontSize: '20px', fontWeight: '800'}} />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="custom-modal"
        contentClassName="custom-modal-content"
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Link
              to="/"
              style={{
                color: 'black',
                textDecoration: 'none',
                fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                fontWeight: 800,
              }}
            >
              <h3>Ubrikk</h3>
            </Link>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul
            className="navbar-nav mr-auto mt-2 mt-lg-0"
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: 'transparent',
              alignItems: 'flex-start',
            }}
          >
            <li className="nav-item">
              <Link className="nav-link" to="/aboutus" style={{ color: 'black', fontWeight: 'bold' }}>
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/productpage" style={{ color: 'black', fontWeight: 'bold' }}>
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" style={{ color: 'black', fontWeight: 'bold' }}>
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/vendorlist" style={{ color: 'black', fontWeight: 'bold' }}>
                Vendors
              </Link>
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MyModal;
