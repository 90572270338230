import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import AboutComponent from "./AboutComponent";
import LearnMoreBtn from "./LearnMoreBtn";
import ContactPage from "./Contactus";
import bgimg from '../../assets/img/bg.jpg';

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("aboutUs");
  const [expanded, setExpanded] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollingUp(scrollTop < (window.lastScrollTop || 0));
      window.lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.querySelector('nav').style.height = collapsed ? '80px' : 'auto';
  }, [collapsed]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="maindiv"
    style={{
      backgroundImage: `url(${bgimg})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "auto",
      minHeight: '900px',
      marginTop: '-15px'
    }}
    >
      <Navbar
        id="navbar-example2"
        style={{
          backgroundColor: 'transparent',
          padding: '10px',
        }}
        expanded={expanded}
        className={`d-flex justify-content-between align-items-center ${scrollingUp ? 'scrolling-up' : ''}`}
        expand="md"
      >
        <Link to="/" style={{ color: 'black', textDecoration: 'none', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif', fontWeight: 800 }}>
          <Navbar.Brand style={{ fontSize: '25px' }}>Ubrikk</Navbar.Brand>
        </Link>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link
              href="#aboutUs"
              onClick={() => toggleTab("aboutUs")}
              className={`nav-link ${activeTab === "aboutUs" && "active"}`}
              style={{ color: activeTab === "aboutUs" ? 'white' : 'black' ,
            backgroundColor: activeTab === "aboutUs" ? 'blue' : 'transparent' ,borderRadius: '8px'}}
            >
              About us
            </Nav.Link>
            <Nav.Link
              href="#contactUs"
              onClick={() => toggleTab("contactUs")}
              className={`nav-link ${activeTab === "contactUs" && "active"}`}
              style={{ color: activeTab === "contactUs" ? 'white' : 'black' ,
            backgroundColor: activeTab === "contactUs" ? 'blue' : 'transparent' ,
          borderRadius: '8px'}}
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="card" data-aos='fade-right' style={{
        border: "none",
        height: "auto",
        backgroundColor: "transparent",
        margin: "15px",
        padding: "10px",
        color: "white"
      }}>
        <div data-spy="scroll" data-target="#navbar-example2" data-offset="0" style={{ color: "black" }}>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-12">
                <div>
                  {activeTab === "aboutUs" && (
                    <>
                      <div className="aboutpage" 
                      style={{marginTop: '-3%'}}
                      >
                        <h2 className="h1-responsive font-weight-bold text-center">
                          Welcome to Ubrikk
                        </h2>
                        <p className="lead grey-text w-responsive text-center mx-auto mb-3">
                          Welcome to Ubrikk.com – your destination for custom orders
                          tailored just for you! At Ubrikk, we believe in providing
                          a personalized shopping experience where you can pick a
                          product and have it delivered according to your
                          specifications.
                        </p>
                      </div>
                      <AboutComponent />
                      <p className="lead grey-text w-responsive text-center mx-auto">
                        <strong><a style={{ textDecoration: "none", cursor: "pointer", color: "blue", fontWeight: "bold" }}>www.ubrikk.com</a></strong>:
                        At Ubrikk, we are passionate about delivering excellence
                        in every custom order. We are a dedicated team committed
                        to providing high-quality products that meet your unique
                        needs. With a focus on craftsmanship and attention to
                        detail, we aim to elevate your shopping experience.
                      </p>
                      <LearnMoreBtn />
                    </>
                  )}
                  {activeTab === "contactUs" && (
                    <>
                      <div className="contactpage" style={{marginTop: '-8%'}}>
                        <ContactPage />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
