import React from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';

const RecommendedProducts = () => {
  return (
    <Container>
       <h3 className="h1-responsive font-weight-bold text-center my-5">
         Recommended Products
       </h3>
      <Carousel activeItem={1} interval={3000} indicators={false} controls={true} className='multi-item-carousel'>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://images.pexels.com/photos/7723756/pexels-photo-7723756.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                <Card.Body>
                  <Card.Title>Label</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://images.pexels.com/photos/6149051/pexels-photo-6149051.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                <Card.Body>
                  <Card.Title>Label</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://images.pexels.com/photos/6694107/pexels-photo-6694107.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' />
                <Card.Body>
                  <Card.Title>Label</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://images.pexels.com/photos/8423383/pexels-photo-8423383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                <Card.Body>
                  <Card.Title>School Bag</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://images.pexels.com/photos/5366873/pexels-photo-5366873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                <Card.Body>
                  <Card.Title>Brown School Bag</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://images.unsplash.com/photo-1559563458-527698bf5295?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aGFuZGJhZ3xlbnwwfHwwfHx8MA%3D%3D' />
                <Card.Body>
                  <Card.Title>Light-Gray Color Handbag</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://5.imimg.com/data5/SELLER/Default/2021/11/SC/RU/ST/135638696/acrylic-glass-name-plate-500x500.jpg' />
                <Card.Body>
                  <Card.Title>Acrylic Glass Name Plate, For Home</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://cbvalueaddrealty.in/wp-content/uploads/2023/04/home-name-plates-1024x683.jpg' />
                <Card.Body>
                  <Card.Title>Wooden Acrylic Name Plate</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className='mb-2'>
                <Card.Img className='img-fluid' src='https://m.media-amazon.com/images/I/71AdZKcbxUL._UF350,350_QL80_.jpg' />
                <Card.Body>
                  <Card.Title>Desk Name Plate with your Name</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </Card.Text>
                  <Button color='primary'>Buy now</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default RecommendedProducts;
