import React from 'react'
import './profilenavbar.css'
import {Link} from 'react-router-dom'
import { useState } from 'react'
import {FiLogIn} from 'react-icons/fi'
import {AiOutlineClose} from 'react-icons/ai'
import {GiHamburgerMenu} from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux';
import team1 from '../../../assets/img/profile/team-1.jpg'
import { DownOutlined, ShoppingCartOutlined } from '@ant-design/icons'

import { getUser, LogoutUser } from 'redux/features/auth';
function ProfileNavbar() {
   const user = useSelector(getUser);
	const dispatch = useDispatch();
   const [istablet,setIstablet]=useState(false)
   const [ismobile,setIsmobile]=useState(false)
   
	const logout = () => dispatch(LogoutUser());
  return (
<div className='navbar'>
<div className='mobile-menu' onClick={()=>setIsmobile(!ismobile)}>{!ismobile?<GiHamburgerMenu className='hamburger'/>:<AiOutlineClose/>}</div>
<div className='logo'>
   <Link to='/' style={{textDecoration: 'none', color: 'black', marginTop: '-10px'}}>
				<h4>Ubrikk</h4>
            </Link>
			</div>
         {/* <div className='nav' style={{ justifyContent: 'center' }}>
				<a href='#'>About Us</a>
				<a href='/profile'>Products</a>
				<a href='#'>Services</a>
				<a href='#'>Vendors</a>
			</div> */}
         <ul className={ismobile?'nav-list-mobile':'nav-list'}>
            <Link to="/aboutus" className='link-tab'>
            <li >About Us</li></Link>
            <Link to="/productpage" className='link-tab'>
            <li  >Products</li></Link>
            <Link to="#" className='link-tab'>
            <li >Service</li></Link>
            <Link to="/vendorlist" className='link-tab'>
            <li >Vendors</li></Link>
            {/* <Link to="/order" className='link-tab cart'>
            <li >Cart</li></Link> */}
         </ul>
         <div>
            
      </div>
 
      <div>
         <div className="order-user-detail" style={{cursor: 'pointer'}}>
        <a href="/order">
          <ShoppingCartOutlined className='shopping-cart'/></a>
         
          <p>John Kumar</p>
        <Link to='/settings' style={{textDecoration: 'none'}}>
        <img src={team1} alt="error" />
        </Link>
        {/* <DownOutlined /> */}
      </div>
      </div>
 
</div>
  )
}

export default ProfileNavbar