import React, { useEffect, useRef } from 'react';
import Header from './Header';
import './Landing.css';
import { AiFillInstagram, AiFillTwitterCircle } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import bgimage from '../../assets/img/bg.jpg';
import bag from '../../bag.svg';
import nameplate from '../../nameplate.svg';
import label from '../../label.svg';
import car from '../../assets/img/car.png';
import { Link } from 'react-router-dom';
import Heading from './Heading';
import Navbar from './Navbar';

function Landing() {
  const landingContentRef = useRef(null);
  const threeLabelsRef = useRef(null);

 

  return (
    <div className='background-container'
      // style={{
      //   backgroundImage: `url(${bgimage})`,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   width: '100%',
      //   height: 'auto',
      //   minHeight: '1400px',
      //   marginTop: '-5px'
      // }}
    >
      {/* <Header /> */}
      <Navbar/>
      <div className="landing-body">
        <div className="landing-content" ref={landingContentRef} style={{marginTop: "-10px"}}>
          <Heading/>
        </div>
        <div className="three-lables" ref={threeLabelsRef}>
          <Link className='links' to='/landingpage' data-aos='fade-down'
            style={{
              textDecoration: 'none',
              color: 'black',
               // Optional: adds a smooth transition effect
            }}
            onMouseOver={(e) => (e.target.style.color = '#0913FA')}
            onMouseOut={(e) => (e.target.style.color = 'black')}
          >
            <div>
              <img src={bag} alt='Kids Bags' />
              <p style={{color: "black", textDecoration: "none"}}>Bags</p>
            </div>
          </Link>
          <Link className='links' to='/landingpage' data-aos='fade-down'  onMouseOver={(e) => (e.target.style.color = '#0913FA')}
            onMouseOut={(e) => (e.target.style.color = 'black')}>
            <div>
              <img src={nameplate} alt='Nameplates' />
              <p>Nameplates</p>
            </div>
          </Link>
          <Link className='links' to='/landingpage' data-aos='fade-down'  onMouseOver={(e) => (e.target.style.color = '#0913FA')}
            onMouseOut={(e) => (e.target.style.color = 'black')}>
            <div>
              <img src={label} alt='Labels' />
              <p>Labels</p>
            </div>
          </Link>
          <Link className='links' to='/customizecar' data-aos='fade-down' onMouseOver={(e) => (e.target.style.color = '#0913FA')}
            onMouseOut={(e) => (e.target.style.color = 'black')}>
            <div>
              <img src={car} alt='Cars' />
              <p>Cars</p>
            </div>
          </Link>
        </div>
        <div className='icon-div' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '50px', marginTop: '13%',  color: "#0913FA" }}>
          <BsFacebook className='icons' style={{ fontSize: '28px', cursor: "pointer", marginRight: "15px" }} />
          <AiFillTwitterCircle className='icons' style={{ fontSize: '31px', cursor: "pointer", marginRight: "15px" }} />
          <AiFillInstagram className='icons' style={{ fontSize: '31px', cursor: "pointer", marginRight: "3px" }} />
        </div>
      </div>
    </div>
  );
}

export default Landing;