import React, { useState, useEffect } from 'react';
import cars from "../../assets1/cars.png";
import backpacks from "../../assets1/backpacks.png";
import clothing from "../../assets1/clothing.png";
import handbag from "../../assets1/handbag.png";
import './heading.css'; // Import your stylesheet
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Heading = () => {
  const images = [cars, backpacks, handbag];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className='heading-page' data-aos='fade-down'>
      <div className='card col-md-25 header-card' data-aos='fade-left' style={{ backgroundColor: "transparent", border: "none" }}>
        <div className="card-body">
        <div data-aos='fade-right' className='hero-para card-title'>CUSTOM ORDERS JUST FOR YOU</div>
  <div className=' hero-heading card-text'>
    Pick a product and have it delivered <br />
    according to your <span data-aos='fade-down' style={{ color: "#0913FA"}}>specifications</span>{' '}
  </div>
        </div>
      </div>

      <div className='card col-md-18 img-card'  data-aos='fade-right' style={{ background: "transparent", border: "none" }}>
        <img className='img'
          src={images[currentImageIndex]}
          alt={`Random Product`}
          style={{ maxWidth: '100%', margin: '10px' }}
        />
      </div>
    </div>
  );
};

export default Heading;
