
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingState, LoginUser } from 'redux/features/auth';
import { AiFillInstagram, AiFillTwitterCircle, AiFillGoogleCircle } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import Bg2 from '../../assets/img/bg2.png';
import {AiOutlineArrowLeft} from 'react-icons/ai'
import { toast } from 'react-toastify';
import './Style.css';

function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isLoading = useSelector(loadingState);
  const [formValues, setValues] = useState({ email: '', password: '' });

  const onChange = (e) => setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.email || !formValues.password) toast.warn('Please provide all fields');
    const res = await dispatch(LoginUser(formValues));
    !res.error &&
      navigate(
        !state || state?.from.pathname === '/' || state?.from?.pathname === '/register'
          ? '/profile'
          : state?.from,
        {
          replace: true,
        }
      );
  };

  useEffect(() => {
    return () => setValues({ email: '', password: '' });
  }, []);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <div>
           <div className='profile-row1'><AiOutlineArrowLeft /> <Link to='/' style={{color: 'black', textDecoration: 'none'}}>&nbsp; <span>Back</span></Link></div>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-6 img-div'>
          <img src={Bg2} alt='error' className='img-fluid' style={{height: "70%", margin: "auto", marginTop: "10%", marginLeft: "10%", marginBottom: "none"}} />
        </div>

        <div className='col-md-6 body-content' style={{margin: "auto", padding: "30px"}}>
          <h4>Welcome Back</h4>
          <b>Pick up from where you left</b>
          <br />
          <br />
          <form onSubmit={onSubmit}>

            <div className='mb-3'>
              <label htmlFor='email' className='form-label' style={{
                width: '100%',
                border: 'none',
                outline: 'none',
                borderRadius: '5px',
                padding: '5px 10px',
                fontSize: '17px',
              }}>
                Email
              </label>
              <input
                type='email'
                className='form-control'
                id='email'
                placeholder='eg abc@example.com'
                value={formValues.email}
                onChange={onChange}
                style={{
                  width: '100%',
                  border: 'none',
                  outline: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  fontSize: '17px',
                }}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='password' className='form-label' style={{
                width: '100%',
                border: 'none',
                outline: 'none',
                borderRadius: '5px',
                padding: '5px 10px',
                fontSize: '17px',
              }}>
                Password
              </label>
              <input
                type='password'
                className='form-control'
                id='password'
                placeholder='***********'
                value={formValues.password}
                onChange={onChange}
                style={{
                  width: '100%',
                  border: 'none',
                  outline: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  fontSize: '17px',
                }}
              />
            </div>
            <Link to='/profile'>
              <button type='submit' className='btn btn-primary' style={{
                width: '100%',
                border: 'none',
                outline: 'none',
                borderRadius: '10px',
                padding: '8px',
                fontSize: '17px',
                backgroundColor: 'blue',
                fontWeight: '800',
                color: 'white',
              }}>
                Login
              </button>{' '}
            </Link>
				</form>
          <div className='mt-3'>
            <span>Or signin with </span>
            <div style={{ alignItems: 'center', margin: 'auto' }}>
              <AiFillGoogleCircle className='icon' style={{ color: '#0913FA', fontSize: '36px' }} />
              <BsFacebook className='icon' style={{ color: '#0913FA', fontSize: '31px' }} />
              <AiFillTwitterCircle className='icon' style={{ color: '#0913FA', fontSize: '35px' }} />
              <AiFillInstagram className='icon' style={{ color: '#0913FA', fontSize: '35px' }} />
            </div>
          </div>
          <br />
          <b style={{ textAlign: 'center' }}>
            Don't have an account? <Link to='/signup'>Create one Here</Link>
          </b>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Signin;