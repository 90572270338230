import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const LearnMoreBtn = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      {/* Button to trigger modal */}
      <button type="button" className="btn btn-primary" onClick={handleShow} style={{backgroundColor: "blue"}}>
        Learn More
      </button>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ubrikk</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: 'black' }}>
          {/* Modal body content */}
          <h4>Customer Satisfaction</h4>
          <p>    Your satisfaction is our priority. We take pride in the
                      positive feedback from our valued customers.</p>

          <h4>Uncompromising Quality</h4>
          <p> Our products undergo rigorous quality control measures to meet and exceed industry standards. From the selection of raw materials to the final packaging, we adhere to the highest quality benchmarks. Each product is a testament to our dedication to craftsmanship, durability, and functionality.</p>

          <h4>Abundance in Every Detail</h4>
          <p>   We believe in providing our customers with more than just a product — we offer an abundance of features, options, and choices. Whether you're looking for variety in design, colors, or functionalities, our extensive range ensures that you find the perfect match for your needs.</p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default LearnMoreBtn;
